<template>
  <div>
    <van-nav-bar
      v-if="GroupData.draw != null"
      :title="GroupData.draw.title"
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
    ></van-nav-bar>
    <div class="main">
      <van-row>
        <van-col class="search" span="24">
          <van-search
            v-model="searchValue"
            show-action
            @search="onSearch"
            placeholder="请输入搜索内容"
          >
            <template #action>
              <div @click="onSearch">搜索</div>
            </template>
          </van-search>
        </van-col>
      </van-row>
      <van-tabs @click="onClickTab">
        <van-tab v-if="allCount" :title="'全部(' + allCount + ')'">
          <Card :statuss="statuss" :ListData="ListData"></Card>
        </van-tab>
        <van-tab v-if="noCount" :title="'未评审(' + noCount + ')'">
          <Card :statuss="statuss" :ListData="noListData"></Card>
        </van-tab>
        <van-tab v-if="okCount != null" :title="'已评审(' + okCount + ')'">
          <Card :statuss="statuss" :ListData="okListData"></Card>
        </van-tab>
      </van-tabs>
      <!-- v-if="statuss == 'under_review'" -->
      <van-button v-if="statuss == 'under_review'" round class="allScore" @click="submit()"
        >提交全部打分</van-button
      >
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import Card from "./components/Scorecard.vue";
import {
  getJuryProgressStatistics,
  pageTeams,
  updateAllScoreApi,
} from "@/api/ScoreList";

export default {
  name: "ScoreList",
  components: {
    Card,
  },
  data() {
    return {
      id: null,
      // 列表数据
      ListData: [],
      GroupData: [],
      allCount: 0,
      searchValue: null,
      isReview: null,
      noListData: [],
      noCount: 0,
      okListData: [],
      okCount: 0,
      statuss: null,
    };
  },
  methods: {
    onClickLeft() {
      // this.$router.go(-1);
      this.$router.back();
    },
    // 获取组别数据
    async getData() {
      await getJuryProgressStatistics({
        id: this.id,
      }).then((res) => {
        // console.log(res);
        this.GroupData = res.data;
      });
    },
    // 获取组内列表数据
    async getTeamData() {
      await pageTeams({ drawGroupId: this.id, isReview: this.isReview })
        .then((res) => {
          // console.log('team', res);
          this.allCount = res.data.count;
          this.ListData = res.data.list;
          // console.log(this.ListData);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取组内列表数据
    async getTeamnoData() {
      await pageTeams({ drawGroupId: this.id, isReview: false })
        .then((res) => {
          // console.log('team', res);
          this.noCount = res.data.count;
          this.noListData = res.data.list;
          // console.log(this.ListData);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取组内列表数据
    async getTeamokData() {
      await pageTeams({ drawGroupId: this.id, isReview: true })
        .then((res) => {
          // console.log('team', res);
          this.okCount = res.data.count;
          this.okListData = res.data.list;
          // console.log(this.ListData);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onClickTab(name) {
      if (name == 0) {
        this.getTeamData();
      } else if (name == 1) {
        this.getTeamnoData();
      } else if (name == 2) {
        this.getTeamokData();
      }
    },
    onSearch() {},
    submit() {
      // this.$confirm("是否提交全部打分？", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      // .then(() => {
      // 全局转圈
      // this.$loading({
      //   lock: true,
      //   text: '正在提交全部打分...',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // });
      updateAllScoreApi({ id: this.id })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            Toast.success(res.message);
            this.getTeamData();
            this.getTeamnoData();
            this.getTeamokData();
          } else {
            Toast.fail(res.message);
          }
        })
        .catch((err) => {
          // this.$notify({ type: "error", message: err.message });
          Toast.fail(err.message);
        });
      // .finally(() => {
      //   this.$loading().close();
      // });
      // })
      // .catch(() => {});
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.statuss = this.$route.query.statuss;
    this.getData();
    this.getTeamData();
    this.getTeamnoData();
    this.getTeamokData();
  },
};
</script>
<style lang="scss" scoped>
.van-nav-bar {
  height: 44px;
}

.main {
  margin-top: 44px;
}
.allScore {
  width: 80vw;
  // padding: 0px 100px;
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  // box-sizing: border-box;
  background: #0a3cca;
  color: #fff;
}
</style>