<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
export default {
};
</script>
<style lang="scss">
#app {
  font-family: PingFang-SC, PingFang-SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #303030;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
}

a {
  text-decoration: none;
}
</style>
