<template>
  <div class="content">
    <van-nav-bar
      title="修改密码"
      left-arrow
      @click-left="onClickLeft"
      :border="false"
    />
    <div class="main">
      <van-row class="form">
        <van-col class="part" span="24">
          <p>原密码</p>
          <van-cell-group>
            <van-field v-model="oldPassword" placeholder="请输入原密码" />
          </van-cell-group>
        </van-col>
        <van-col class="part" span="24">
          <p>新密码</p>
          <van-cell-group>
            <van-field v-model="password" placeholder="请输入新密码" />
          </van-cell-group>
        </van-col>
        <van-col class="part" span="24">
          <p>确认密码</p>
          <van-cell-group>
            <van-field v-model="password2" placeholder="请再次输入新密码" />
          </van-cell-group>
        </van-col>
        <van-col span="24" class="btns">
          <van-row>
            <van-col span="24">
              <van-button round type="info" @click="Editpass()"
                >确认</van-button
              >
            </van-col>
          </van-row>
        </van-col>
      </van-row>
    </div>
  </div>
</template>
      
    <script>
import { EditPass } from "@/api/Home/index.js";
export default {
  name: "Home",
  components: {
    //   Card,
  },
  data() {
    return {
      active: 2,
      oldPassword: "",
      password: "",
      password2: "",
    };
  },
  methods: {
    // onClick(name, title) {
    //   Toast(title);
    // },
    onClickLeft() {
      this.$router.go(-1);
    },
    async Editpass() {
      await EditPass({
        oldPassword: this.oldPassword,
        password: this.password,
        password2: this.password2,
      }).then((res) => {
        if (res.code === 0) {
          this.$notify({ type: "success", message: "修改成功" });
          this.$router.go(-1);
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });
    },
  },
};
</script>
      <style lang="scss" scoped>
.content {
  height: 100%;
  :deep .van-icon {
    color: #303030;
  }
}
.main {
  padding: 12px 18px;
  text-align: left;
  border-top: 10px solid #f8f8f8;
  box-sizing: border-box;
  height: calc(100% - 50px);
  position: relative;
  .form {
    .part {
      margin-top: 15px;
      font-size: 14px;
      p {
        margin-bottom: 12px;
        padding-left: 12px;
      }
      .van-cell-group {
        border: 1px solid #d8d8d8;
        border-radius: 4px;
      }
    }
    .btns {
      padding: 0px 18px;
      position: absolute;
      bottom: 22px;
      right: 0px;
      .van-row {
        width: 100%;
      }
      .van-button {
        width: 100%;
      }
    }
  }
}
</style>