<template>
  <div class="listcont">
    <Views ref="Views" :datas="FileData" :type="type"></Views>
    <!-- <vue-office-pdf :src="FileData" /> -->
    <van-row class="listitem" v-for="item in ListData" :key="item.id">
      <van-col class="title" span="24">
        <div class="score">
          <img src="@/assets/images/team-logo.png" alt="" />
          {{ item.title }}
        </div>
        <div class="score scores" v-if="item.isReview">
          <img src="@/assets/images/start.png" alt="" />
          <span v-if="item.totalSocres">{{ item.totalSocres }}分</span>
        </div>
      </van-col>
      <!-- <van-col span="12"> 学校名称：{{ item.schoolName }} </van-col>
      <van-col span="12">学院：{{ item.schoolDept }}</van-col> -->
      <van-col span="12"> 所属组名：{{ item.drawGroupName }} </van-col>
      <van-col span="12">出场次序：{{ item.comingNo }}</van-col>
      <van-col span="12">团队编号：{{ item.drawNo }}</van-col>
      <van-col span="12">组别：{{ item.groupName }}</van-col>
      <van-col class="title" span="24"> 作品 </van-col>
      <van-col span="24" class="work">
        <van-row v-for="(val, index) in item.workInfoList" :key="index">
          <van-col class="view" v-if="val.fieldValue != ''">
            <span class="filename">{{ val.fieldsValueName }}</span
            >.{{ val.fieldValueType }}
            <van-button plain type="info" @click="view(val)">查看</van-button>
          </van-col>
          <van-col class="view" v-else> 暂未提交作品 </van-col>
          <!-- <iframe :src="FileData" frameborder="0" style="width: 100%; height: 89vh"></iframe> -->
        </van-row>
      </van-col>
      <van-col class="btn" span="24">
        <!-- <div v-if="item.totalSocres" style="flex: 1;">
          总分：<span>{{ item.totalSocres }}</span>分
        </div> -->
        <van-button round @click="Score(item)" v-if="!item.isReview"
          >去打分</van-button
        >
        <van-button class="btn-details" round @click="Score(item)" v-else
          >查看详情</van-button
        >
      </van-col>
    </van-row>
    <!-- <van-popup v-model="show"> -->
    <!-- <iframe
        :src="FileData"
        frameborder="0"
        style="width: 100%; height: 80vh"
      ></iframe> -->
    <!-- <pdf
        :src="pdfurl"
        :page="currentPage"
        @num-pages="pageCount = $event"
        @page-loaded="currentPage"
        
      ></pdf> -->
    <!-- </van-popup> -->
  </div>
</template>
<script>
// import VueOfficePdf from '@vue-office/pdf'
import { ImagePreview } from "vant";
import Views from "./previewFile";
// import pdf from "vue-pdf";
export default {
  props: {
    ListData: Array,
    statuss: String,
  },
  components: {
    // VueOfficePdf
    Views,
    // pdf,
  },
  data() {
    return {
      FileData: null,
      show: false,
      type: null,

      numPages: 0,
      currentPage: 1,
      pageCount: 0,
      pdfurl: null,
    };
  },
  methods: {
    Score(val) {
      this.$router.push({ path: "/grade", query: { id: val.id,statuss: this.statuss } });
    },
    view(item) {
      // console.log(item);
      this.show = true;
      this.FileData = item.fieldValue;
      if (item.fieldValue != "") {
        // this.pdfurl = item.fieldValue;
        if (
          item.fieldValueType == "jpg" ||
          item.fieldValueType == "png" ||
          item.fieldValueType == "jpeg"
        ) {
          let arr = [];
          arr.push(item.fieldValue);
          ImagePreview(arr);
          //   ImagePreview([
          //   "https://img01.yzcdn.cn/vant/apple-1.jpg",
          // ]);
        } else {
          this.type = item.fieldValueType;
          this.$nextTick(() => {
            this.$refs.Views.isShow = true;
            this.$refs.Views.showFile(item);
          });
        }
      } else {
        this.$toast("暂无文件");
      }
    },
  },

  created() {
    // console.log(this.ListData);
    if (this.ListData != "") {
      this.ListData.forEach((item) => {
        // console.log(item);
        if (item.workInfoList != "") {
          item.workInfoList.forEach((val) => {
            // val.fieldValue = "https://img01.yzcdn.cn/vant/apple-1.jpg";
            // val.fieldValue =
            //   "https://bdjdmcyc.oss-cn-beijing.aliyuncs.com/crmfiles/%E6%A8%A1%E6%9D%BF/%E6%A0%87%E7%AD%BE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx";
            // console.log(val);
            const name = decodeURIComponent(val.fieldValue).split("/").pop();
            val.fieldsValueName = name.split(".")[0];
            val.fieldValueType = name.split(".")[1];
            // console.log(val);
          });
        }

        // // .split('.')[0]
        // // console.log(item.fieldsName);
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.listitem {
  padding: 15px 20px;
  font-size: 14px;
  text-align: left;
  border-top: 10px solid #f8f8f8;
  line-height: 30px;

  .title {
    display: flex;
    align-content: center;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 22px;
    font-weight: bold;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    .score {
      display: flex;
      align-items: center;
    }

    .scores {
      color: #ef8e26;
      font-weight: normal;
    }
  }

  .work {
    .view {
      display: flex;
      align-content: center;

      .van-button {
        border: #fff;
        height: 30px;
        color: #0a3cca;
      }

      .filename {
        width: 150px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .btn {
    display: flex;
    justify-content: end;

    .van-button {
      width: 90px;
      height: 30px;
      background: #0a3cca;
      color: #fff;
    }

    .btn-details {
      background: #ef8e26;
    }
  }
}

.van-popup--center {
  width: 100%;
}
</style>