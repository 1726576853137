<template>
  <div class="home">
    <div class="cont-bg">
      <img src="@/assets/images/login-logo.png" alt="" />
      <p>欢迎使用全国高校商业精英挑战赛</p>
      <p>赛事一体化评审系统</p>
    </div>
    <van-form @submit="onSubmit">
      <van-cell-group>
        <van-field v-model="username" name="username" placeholder="请输入账号" :rules="[{ required: true, message: '请输入账号' }]">
        </van-field>
        <img src="@/assets/images/login-name.png" alt="" />
      </van-cell-group>
      <van-cell-group>
        <van-field v-model="password" type="password" name="password" placeholder="请输入密码"
          :rules="[{ required: true, message: '请输入密码' }]">
        </van-field>
        <img src="@/assets/images/login-pass.png" alt="" />
      </van-cell-group>
      <div>
        <van-button round block type="info" native-type="submit" style="background: #0a3cca; border: none">
          登录
        </van-button>
      </div>
    </van-form>
    <div class="bottom">
      <p>Copyright@2023 中国国际贸易促进委员会商业行业委员会</p>
    </div>
  </div>
</template>
  
<script>
import { Login } from "@/api/Login/index.js";
import { setToken } from "@/utils/token-util";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    async onSubmit(values) {
      // console.log("submit", values);
      await Login(values)
        .then((res) => {
          // console.log(res);
          if (res.code === 0) {
            this.$notify({ type: "success", message: "登陆成功" });
            setToken(res.data.access_token);
            this.$router.push("/");
          } else {
            this.$notify({ type: "warning", message: res.message });
          }
        })
    },
  },
};
</script>
<style lang="scss" scoped>
.cont-bg {
  width: 100%;
  height: 370px;
  background: url("../../assets/images/login-bg.png") no-repeat;
  background-size: contain;

  // text-align: left;
  img {
    width: 158px;
    height: 64px;
    margin-top: 100px;
  }

  p {
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    line-height: 28px;
  }
}

.bottom {
  // width: 100%;
  height: 125px;
  background: url("../../assets/images/login-bottom.png") no-repeat;
  background-size: contain;
  padding-top: 80px;
  box-sizing: border-box;

  p {
    font-size: 12px;
    color: #909090;
    line-height: 17px;
  }
}

.van-form {
  padding: 30px 28px 30px;

  .van-cell-group {
    position: relative;
    border: 1px solid #E0E0E0;
    border-radius: 23px;
    margin-bottom: 50px;
    padding: 12px 52px;


    .van-cell {
      background: none;
      padding: 0;
      overflow: visible;
    }

    img {
      position: absolute;
      top: 13px;
      left: 22px;
      width: 18px;
      height: 20px;
    }


  }

  // :deep .van-field__control {
  //   padding: 12px 52px;
  //   border-radius: 23px;
  //   color: #303030;
  //   border: 1px solid #e0e0e0;
  // }

  :deep .van-field__error-message {
    position: absolute;
    bottom: -22px;
    left: 12px;
  }

  :deep .van-hairline--top-bottom::after {
    border: none;
  }



  .van-cell-group:first-child {
    margin-bottom: 25px;
  }
}

.van-cell::after {
  border: none;
}
.van-hairline--top-bottom:after{
  border: none;
}
</style>