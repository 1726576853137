<template>
  <div class="listcont">
    <div v-if="data != ''">
      <van-row class="listitem" v-for="item in data" :key="item.id">
        <van-col span="24" class="title">{{ item.title }}</van-col>
        <van-col span="24">
          <p>竞赛阶段：{{ item.formatTitle }}</p>
          <p>团队数量：{{ item.teamCount }}</p>
          <p>所属大赛：{{ item.competitionsName }}</p>
          <p>评审时间：{{ item.startTime }} - - {{ item.endTime }}</p>
        </van-col>
        <van-col class="btn" span="24">
          <van-button round @click="toScore(item)">进入评审</van-button>
        </van-col>
      </van-row>
    </div>
    <div class="null" v-else>
      <img src="@/assets/images/no-works.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Array,
    statuss: String,
  },
  data() {
    return {};
  },
  methods: {
    toScore(item) {
      this.$router.push({
        path: "/scoreList",
        query: {
          id: item.id,
          statuss: this.statuss,
        },
      });
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.listitem {
  padding: 15px 20px;
  font-size: 14px;
  text-align: left;
  border-top: 10px solid #f8f8f8;
  line-height: 30px;

  .title {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 25px;
  }

  .btn {
    display: flex;
    justify-content: end;

    .van-button {
      width: 90px;
      height: 30px;
      background: #0a3cca;
      color: #fff;
    }
  }
}

.null {
  img {
    width: 290px;
    height: 160px;
    margin: 80px 0 50px;
  }
}
</style>