import service from "../../router/request";

// 获取团队信息
export const getTeam = (id) => {
    return service({ url: `/business/schoolTeam/${id}`, method: `get` })
}

// 获取打分表信息
export const getTeamScore = (data) => {
    return service({ url: `/business/schoolTeam/getPreScore`, method: `get`, params: data })
}

export const seedScore = (data) => {
    return service({ url: `/business/schoolTeam/addScore`, method: `post`, data })
}


