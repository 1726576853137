import service from "../../router/request";

// 获取组别数据
export const getJuryProgressStatistics = (data) => {
    return service({ url: `/business/groupDrawing/getJuryProgressStatistics`, method: `get`, params: data })
}
// 获取组别内列表数据
export const pageTeams = (data) => {
    return service({ url: `/business/schoolTeam/page`, method: `get`, params: data })
}

// 提交全部打分
export const updateAllScoreApi = (data) => {
    return service({ url: `/business/groupDrawing/updateAllScore`, method: `post`, data })
}