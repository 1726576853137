import { render, staticRenderFns } from "./Scorecard.vue?vue&type=template&id=cda37a64&scoped=true"
import script from "./Scorecard.vue?vue&type=script&lang=js"
export * from "./Scorecard.vue?vue&type=script&lang=js"
import style0 from "./Scorecard.vue?vue&type=style&index=0&id=cda37a64&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_prettier@2.2.1_vue-template-compiler@2.6.11_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cda37a64",
  null
  
)

export default component.exports