import Vue from "vue";

// 引vant ui
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import App from "./App.vue";

import router from "./router";
import store from "./store";



// 引入适配插件
import 'amfe-flexible';


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

