import axios from 'axios';
import { getToken } from '../utils/token-util';

// 基准地址
// const BASE_URL = '/api';
const BASE_URL = process.env.VUE_APP_API_BASE_URL;

// const BASE_URL = 'http://ccpitaccountadmin.bdjd.cc:9191/api';


const service = axios.create({
    baseURL: BASE_URL,
    timeout: 10000,
    headers: { 'Content-Type': 'application/json' },
});

// request 拦截器
service.interceptors.request.use(
    (config) => {
        const token = getToken();
        config.headers.Authorization = token;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// response 拦截器
service.interceptors.response.use(
    (res) => {
        // if (response.data.code == 200) {
        //   return response.data;
        // } else {
        //   ElMessage.error('token身份令牌过期,请重新登录');
        //   router.push("/login");
        // }
        return res.data;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default service