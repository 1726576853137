<template>
  <div class="content">
    <div class="count">
      <van-row class="header">
        <van-col span="6">
          <img
            v-if="userInfo != null"
            style="border-radius: 50%"
            :src="userInfo.avatar"
            alt=""
          />
        </van-col>
        <van-col v-if="userInfo != null" span="10" class="user-name">{{
          userInfo.nickname
        }}</van-col>
        <van-col span="8">
          <van-button icon="contact" type="info" @click="toUser()"
            >个人中心</van-button
          >
        </van-col>
      </van-row>
    </div>
    <van-row class="main">
      <van-col class="search" span="24">
        <van-search
          v-model="searchValue"
          show-action
          @search="onSearch"
          placeholder="请输入搜索内容"
        >
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
      </van-col>
    </van-row>
    <van-tabs @click="onClick">
      <van-tab name="under_review" :title="'待评审(' + reviewcount + ')'">
        <Card :data="reviewlist" :statuss="statuss" :count="reviewcount"></Card>
      </van-tab>
      <van-tab name="not_started" :title="'未开始(' + notcount + ')'">
        <Card :data="notlist" :statuss="statuss" :count="notcount"></Card>
      </van-tab>
      <van-tab name="completed" :title="'已评审(' + completedcount + ')'">
        <Card
          :data="completedlist"
          :statuss="statuss"
          :count="completedcount"
        ></Card>
      </van-tab>
    </van-tabs>
  </div>
</template>
  
<script>
import { pageGroupDrawings, getUserInfo } from "@/api/Home";
import Card from "./components/card.vue";
export default {
  name: "Home",
  components: {
    Card,
  },
  data() {
    return {
      active: 2,
      reviewlist: null,
      reviewcount: 0,
      notlist: null,
      notcount: 0,
      completedlist: null,
      completedcount: 0,
      userInfo: null,
      searchValue: "",
      statuss: null,
    };
  },
  methods: {
    // 用户中心
    toUser() {
      this.$router.push("/usercenter");
    },
    // 获取数据
    async getreviewGroup() {
      try {
        const res = await pageGroupDrawings({ statuss: "under_review" });
        this.statuss = "under_review";
        // const res = await pageGroupDrawings();
        // console.log('home', res);
        this.reviewlist = res.data.list;
        this.reviewcount = res.data.count;
      } catch (error) {
        console.error("获取数据失败:", error);
      }
    },
    async getnotGroup() {
      try {
        const res = await pageGroupDrawings({ statuss: "not_started" });
        this.statuss = "not_started";
        this.notlist = res.data.list;
        this.notcount = res.data.count;
      } catch (error) {
        console.error("获取数据失败:", error);
      }
    },
    async getcompletedGroup() {
      try {
        const res = await pageGroupDrawings({ statuss: "completed" });
        this.statuss = "completed";
        this.completedlist = res.data.list;
        this.completedcount = res.data.count;
      } catch (error) {
        console.error("获取数据失败:", error);
      }
    },
    // 获取用户信息
    async getInfo() {
      try {
        const res = await getUserInfo();
        // console.log("homeinfo", res);
        this.userInfo = res.data;
      } catch (error) {
        // console.error('获取数据失败:', error);
      }
    },
    onSearch() {},
    onClick(name) {
      if (name == "under_review") {
        this.getreviewGroup();
      } else if (name == "not_started") {
        this.getnotGroup();
      } else {
        this.getcompletedGroup();
      }
    },
  },
  created() {
    this.getInfo();
    this.getreviewGroup();
    this.getnotGroup();
    this.getcompletedGroup();
  },
};
</script>
<style lang="scss" scoped>
.content {
  height: 100%;

  .van-nav-bar {
    background-color: none;
  }
}

.count {
  width: 100%;
  height: 150px;
  background: url("../../assets/images/home-bg.png") no-repeat;
  background-size: cover;
  overflow: hidden;

  .header {
    margin-top: 50px;
    padding-left: 30px;
    position: relative;
    height: 66px;

    .van-col {
      display: flex;
      height: 100%;
      align-content: center;
      flex-wrap: wrap;
    }

    img {
      width: 66px;
      height: 66px;
    }

    .user-name {
      font-size: 20px;
      color: #fff;
      text-align: left;
      padding-left: 10px;
    }

    .van-button {
      box-sizing: border-box;
      padding-left: 13px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 85px;
      height: 30px;
      border-radius: 0;
      border-top-left-radius: 99px;
      border-bottom-left-radius: 99px;
      font-size: 12px;

      .van-button__content {
        width: 68px;
      }
    }
  }
}
:deep .van-tabs__line {
  background-color: #0a3cca;
  width: 95px;
}
:deep .van-tab--active {
  color: #0a3cca;
}
</style>