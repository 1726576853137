<template>
  <div>
    <van-nav-bar
      title="打分"
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
    />
    <div class="main">
      <van-row class="team-name"> 团队名称：{{ TeamData.title }} </van-row>
      <van-row class="part" v-for="item in workList" :key="item.id">
        <van-col class="title" span="24">
          <div class="sign"></div>
          {{ item.title }}
        </van-col>
        <van-col span="24" class="level">
          {{ item.describes }}
        </van-col>
        <van-col
          class="strip"
          span="24"
          v-for="val in item.childList"
          :key="val.id"
        >
          <van-row>
            <van-col span="18" style="padding-right: 3px">
              {{ val.title }} (总分：{{ val.source }})</van-col
            >
            <van-col span="6" class="input">
              <van-field
                :disabled="val.childList == null ? false : true"
                @blur="inputChange(val)"
                v-model="val.getScore"
                input-align="center"
              />
              <span>分</span>
              <!-- <span>总分：{{ item.source }}</span> -->
            </van-col>
            <div v-if="val.childList != [] && val.childList != null">
              <van-col
                class="strip"
                span="24"
                v-for="ele in val.childList"
                :key="ele.id"
              >
                <van-row>
                  <van-col span="18" style="padding-right: 3px">
                    {{ ele.title }} (总分：{{ ele.source }})</van-col
                  >
                  <van-col span="6" class="input">
                    <van-field
                      @blur="inputChange(ele)"
                      v-model="ele.getScore"
                      input-align="center"
                    />
                    <span>分</span>
                    <!-- <span>总分：{{ item.source }}</span> -->
                  </van-col>
                </van-row>
              </van-col>
            </div>
          </van-row>
        </van-col>
        <van-col class="strip all" span="24">
          <van-row>
            <van-col span="18">第一部分总分</van-col>
            <van-col span="6" class="input">
              <van-field
                disabled
                v-model="item.totalScore"
                input-align="center"
              />
              <span>分</span>
            </van-col>
          </van-row>
        </van-col>
      </van-row>
    </div>
    <van-row class="btns">
      <van-button round type="default" @click="onClickLeft()">取消</van-button>
      <van-button
        round
        type="info"
        @click="seedingScore()"
        v-if="statuss == 'under_review'"
        >确认</van-button
      >
    </van-row>
  </div>
</template>
<script>
import { Toast } from "vant";
import { Dialog } from "vant";
import { getTeam, getTeamScore, seedScore } from "@/api/Grade";
export default {
  data() {
    return {
      id: null,
      value: "",
      TeamData: {},
      workList: [],
      statuss: null,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    async getData() {
      await getTeam(this.id).then((res) => {
        // console.log(res);
        this.TeamData = res.data.teamInfo;
      });
    },
    async getTeamScoreData() {
      await getTeamScore({ id: this.id }).then((res) => {
        this.workList = res.data;
        // console.log(this.workList);
      });
    },
    inputChange(val) {
      console.log(val);
      if (Number(val.getScore) < 0 || val.getScore > val.source) {
        Toast.fail("请输入规定范围内的分数");
        val.getScore = 0;
        return;
      }

      // 计算总分
      // this.workList.forEach((item) => {
      //   if (item.childList != null) {
      //     item.childList.forEach((ele) => {
      //       ele
      //     });
      //   }else{
      //     item.totalScore = val.getScore;
      //   }
      // });
    },
    seedingScore() {
      Dialog.confirm({
        title: "提示",
        message: "请确定是否提交打分",
      })
        .then(() => {
          seedScore({
            teamId: this.id,
            dataList: this.workList,
          })
            .then((res) => {
              // console.log(res);
              Toast.success(res.message);
              this.$router.back();
            })
            .catch((msg) => {
              Toast.fail(msg.message);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.statuss = this.$route.query.statuss;
    this.getData();
    this.getTeamScoreData();
  },
};
</script>
<style lang="scss" scoped>
.van-nav-bar {
  height: 44px;

  .van-nav-bar__content {
    height: 44px !important;
  }
}

.main {
  margin-top: 44px;
  background: #f8f8f8;
  padding-top: 1px;
  text-align: left;

  .team-name {
    font-size: 16px;
    color: #0a3cca;
    line-height: 22px;
    font-weight: bold;
    padding: 10px 15px;
    background: #fff;
    margin-top: 10px;
  }

  .part {
    margin-top: 10px;
    background: #fff;

    .van-col {
      padding: 8px 20px;
    }

    .title {
      position: relative;
      font-size: 14px;
      font-weight: bold;
      text-align: left;

      .sign {
        width: 5px;
        height: 20px;
        background: #0a3cca;
        border-top-right-radius: 2.5px;
        border-bottom-right-radius: 2.5px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .level {
      font-size: 12px;
      color: #606060;
    }

    .strip {
      font-size: 12px;
      border-top: 1px solid #f3f9ff;

      .van-row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .strip {
          padding-right: 6px;
          // margin-top: 2px;
        }

        > .van-col {
          padding: 0;

          .van-cell {
            width: 50px;
            height: 24px;
            border-radius: 2px;
            border: 1px solid #d8d8d8;
            padding: 0;
          }
        }

        .input {
          display: flex;
          align-items: center;

          span {
            margin-left: 5px;
          }
        }
      }
    }

    .all {
      font-weight: bold;
    }
  }

  .part:last-child {
    padding-bottom: 70px;
  }
}

.btns {
  position: fixed;
  bottom: 20px;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;

  .van-button {
    width: 150px;
    height: 45px;
    margin-left: 15px;
    box-shadow: 0 2px 2px 0px rgba(49, 74, 145, 0.47);
  }
}
</style>