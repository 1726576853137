<template>
  <div class="viewBox" v-if="isShow">
    <iframe
      v-if="isOffice"
      :src="datas"
      frameborder="0"
      style="width: 100%; height: 89vh"
    ></iframe>
    <!-- pdf预览 -->
    <div class="wrap-pdf" v-if="type === 'pdf'">
      <div class="pdf">
        <div class="pdf-tab">
          <div class="btn-def" @click.stop="prePage"><span>上一页</span></div>
          <div class="btn-def" @click.stop="nextPage"><span>下一页</span></div>
        </div>
        <div style="text-align: center">{{ pageNum }}/{{ pageTotalNum }}</div>
        <div class="any-scroll-view">
          <div ref="body">
            <pdf
              id="pdfPreview"
              ref="pdf"
              :src="pdfSrc"
              :page="pageNum"
              :rotate="pageRotate"
              @password="password"
              @progress="loadedRatio = $event"
              @page-loaded="pageLoaded($event)"
              @num-pages="pageTotalNum = $event"
              @error="pdfError($event)"
              @link-clicked="page = $event"
            >
            </pdf>
          </div>
        </div>
      </div>
      <!-- <pdf v-for="item in numPages" :key="item" :src="pdfSrc" :page="item"/> -->
    </div>
    <!-- 表格组件 -->
    <div class="table-box" v-if="type === 'excel'">
      <van-tabs
        class="table-tab"
        v-if="sheetNames.length"
        title-active-color="#07AC7F"
        color="#07AC7F"
        @click="clickTab"
      >
        <van-tab
          v-for="(item, index) in sheetNames"
          :key="index"
          :name="item"
          :title="item"
        ></van-tab>
      </van-tabs>
      <div class="tableBox" ref="excPreview"></div>
    </div>
    <iframe
      v-if="type === 'mp4'"
      :src="viedoData"
      frameborder="0"
      style="width: 100%; margin-top: 6vh; height: 90vh"
    ></iframe>

    <!-- 关闭按钮 -->
    <van-icon class="closeBtn" name="cross" @click="isShow = false" />
  </div>
</template>
<script>
// import { ImagePreview } from "vant";
import pdf from "vue-pdf";
import XLSX from "xlsx";
export default {
  name: "PreviewFile",
  components: {
    pdf,
  },
  props: {
    datas: {},
    type: {},
  },
  data() {
    return {
      isShow: false,
      numPages: 1,
      pdfSrc: "",
      sheetNames: [],
      pageNum: 1,
      wsObj: {},
      pageTotalNum: 1,
      pageRotate: 0,
      // 加载进度
      loadedRatio: 0,
      curPageNum: 0,
      viedoData: null,
      isOffice: false,
    };
  },

  methods: {
    showFile(newVal) {
      //   console.log("----", newVal);
      console.log(this.type);
      // if (this.type === "img") {
      //   ImagePreview({
      //     images: [newVal],
      //     onClose() {
      //       this.isShow = false;
      //     },
      //   });
      // } else
      if (this.type === "pdf") {
        this.pdfSrc = pdf.createLoadingTask(newVal.fieldValue);
      } else if (this.type === "mp4") {
        this.viedoData = newVal.fieldValue;
        // console.log(this.viedoData);
      } else if (
        this.type === "xlsx" ||
        this.type === "xls" ||
        this.type === "doc" ||
        this.type === "docx"
      ) {
        const date = Date.parse(new Date());
        this.datas =
          "https://view.officeapps.live.com/op/view.aspx?src=" +
          newVal.fieldValue +
          "?t=" +
          date;
        this.isOffice = true;
        // var xhr = new XMLHttpRequest();
        // xhr.open("get", newVal.fieldValue, true);
        // xhr.responseType = "arraybuffer";
        // let _this = this;
        // xhr.onload = function () {
        //   var binary = "";
        //   if (xhr.status === 200) {
        //     var bytes = new Uint8Array(xhr.response);
        //     var length = bytes.byteLength;
        //     for (var i = 0; i < length; i++) {
        //       binary += String.fromCharCode(bytes[i]);
        //     }
        //     var wb = XLSX.read(binary, { type: "binary" });
        //     // var wsname = wb.SheetNames[0];
        //     // var ws = wb.Sheets[wsname];
        //     // _this.sheetNames = [...wb.SheetNames] // 数组
        //     _this.wsObj = { ...wb.Sheets };
        //     _this.changeExcel(_this.sheetNames[0]);
        //   }
        // };
        // xhr.send();
      }
    },
    clickTab(name) {
      this.changeExcel(name);
    },
    prePage() {
      var p = this.pageNum;
      p = p > 1 ? p - 1 : this.pageTotalNum;
      this.pageNum = p;
    },
    nextPage() {
      var p = this.pageNum;
      p = p < this.pageTotalNum ? p + 1 : 1;
      this.pageNum = p;
    },
    password(updatePassword) {
      updatePassword(prompt('password is "123456"'));
    },
    pageLoaded(e) {
      this.curPageNum = e;
    },
    pdfError(error) {
      console.error(error);
    },
    changeExcel(item) {
      // 获取当前选中表格对象
      const ws = this.wsObj[item];
      console.log(ws);
      const keyArr = Object.keys(ws) || [];
      const HTML =
        keyArr.length > 1
          ? XLSX.utils.sheet_to_html(ws)
          : '<html><head><meta charset="utf-8"/>' +
            '<title>SheetJS Table Export</title></head><body><div class="myTable">暂无数据</div></body>' +
            "</html>";
      this.$nextTick(() => {
        this.$refs.excPreview.innerHTML = HTML;
        // 获取表格dom元素
        const tables = this.$refs.excPreview.children[2];
        // 添加完毕后 通过空格将数组组装为字符串
        tables.className = "myTable";
      });
    },
  },
};
</script>
  
<style lang="scss" scoped>
.excel-container {
  width: 100%;
}

table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  border: 1px solid #929292;
  width: auto;
  height: auto;
  color: #333333; // 合并边框

  th,
  tr {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #ffffff;
    padding: 10px;
    border: 1px solid #929292;

    td {
      font-weight: normal;
      text-align: center;
      border: 1px solid #929292;
    }
  }
}

.tableBox {
  width: 100vw;
  height: calc(100vh - 44px);
  overflow: auto;
}

// 表格边框
.pdf-tab {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 0.4rem;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pdf-tab .btn-def {
  border-radius: 0.2rem;
  font-size: 0.5rem;
  // height: 40px;
  margin-top: 40px;
  // width: 6.4rem;
  text-align: center;
  // line-height: 1.93333rem;
  background: #409eff;
  color: #fff;
  // margin-bottom: 1.26667rem;
}

.pdf-total {
  text-align: center;
  font-size: 1.45333rem;
}

.pdf-process,
.pdf-total {
  text-align: center;
  font-size: 1.45333rem;
}

.pdf-num {
  margin-bottom: 1.2rem;
}

.pdf-box,
.word-box,
.table-box,
.txt-box {
  width: 100vw;
  height: 100vh;
}

.viewBox {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;

  .closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    font-size: 24px;
  }
}
</style>
  
  