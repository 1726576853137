<template>
  <div class="content">
    <van-nav-bar
      title="编辑资料"
      left-arrow
      @click-left="onClickLeft"
      :border="false"
    />
    <div class="main">
      <van-row class="form">
        <van-col class="photo" span="24">
          <div class="userphoto">
            <img style="border-radius: 50%;" :src="userInfo.avatar" alt="" class="user" />
            <div class="upload">
              <van-uploader>
                <img src="../../assets/images/upload.png" alt="" />
              </van-uploader>
            </div>
          </div>
        </van-col>
        <van-col class="title" span="24"> 个人信息 </van-col>
        <van-col class="part" span="24">
          <p>账号</p>
          <van-cell-group>
            <van-field v-model="userInfo.username" disabled />
          </van-cell-group>
        </van-col>
        <van-col class="part" span="24">
          <p>联系电话</p>
          <van-cell-group>
            <van-field v-model="userInfo.phone" />
          </van-cell-group>
        </van-col>
        <van-col class="part" span="24">
          <p>联系邮箱</p>
          <van-cell-group>
            <van-field v-model="userInfo.email" />
          </van-cell-group>
        </van-col>
        <van-col span="24" class="btns">
          <van-row>
            <van-col span="24">
              <van-button round type="info">确认</van-button>
            </van-col>
          </van-row>
        </van-col>
      </van-row>
    </div>
  </div>
</template>
      
    <script>
import { getUserInfo } from "@/api/Home";
export default {
  components: {
    //   Card,
  },
  data() {
    return {
      userInfo: null,
    };
  },
  methods: {
    // onClick(name, title) {
    //   Toast(title);
    // },
    onClickLeft() {
      this.$router.go(-1);
    },
    // 获取用户信息
    async getInfo() {
      try {
        const res = await getUserInfo();
        console.log("homeinfo", res);
        this.userInfo = res.data;
      } catch (error) {
        // console.error('获取数据失败:', error);
      }
    },
  },
  created() {
    this.getInfo();
  },
};
</script>
      <style lang="scss" scoped>
.content {
  height: 100%;
  :deep .van-icon {
    color: #303030;
  }
}
.main {
  padding: 12px 18px;
  text-align: left;
  border-top: 10px solid #f8f8f8;
  box-sizing: border-box;
  height: calc(100% - 50px);
  position: relative;
  .form {
    .title {
      color: #0a3cca;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    .part {
      margin-top: 15px;
      font-size: 14px;
      p {
        margin-bottom: 12px;
        padding-left: 12px;
      }
      .van-cell-group {
        border: 1px solid #d8d8d8;
        border-radius: 4px;
      }
    }
    .btns {
      padding: 0px 18px;
      position: absolute;
      bottom: 22px;
      right: 0px;
      .van-row {
        width: 100%;
      }
      .van-button {
        width: 100%;
      }
    }
    .photo {
      display: flex;
      justify-content: center;
      margin: 30px 0;
      .userphoto {
        position: relative;
      }
      .user {
        width: 90px;
        height: 90px;
      }
      .upload {
        width: 22px;
        height: 22px;
        position: absolute;
        bottom: 0;
        right: 0;
        .van-uploader {
          width: 22px;
          height: 22px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
</style>