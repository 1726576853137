import Vue from "vue";
import VueRouter from "vue-router";
// import Index from "@/views/Home/Home.vue";
import Home from "@/views/Home/index.vue";
import Login from "@/views/Login/index.vue";
import ScoreList from "@/views/ScoreList/index.vue";
import Grade from "@/views/Grade/index.vue";
import Usercenter from "@/views/Usercenter/index.vue";
import Editpass from "@/views/Editpass/index.vue";
import Editinfo from "@/views/Editinfo/index.vue";


Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   component: Index,
  //   children: [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/scoreList",
    component: ScoreList,
  },
  {
    path: "/grade",
    component: Grade,
  },
  {
    path: "/usercenter",
    component: Usercenter,
  },
  {
    path: "/editpass",
    component: Editpass,
  },
  {
    path: "/editinfo",
    component: Editinfo,
  },
  //   ]
  // },
  {
    path: "/login",
    component: Login,
  },

];

const router = new VueRouter({
  mode: "history",
  routes,
});


router.beforeEach((to, from, next) => {
  if (to.path == "/login") return next()
  let token = sessionStorage.access_token;
  if (!token) return next('/login');
  next();
})

// router.beforeEach((to, from, next) => {
//   try {
//     if (to.path === "/login") return next();
//     let token = sessionStorage.getItem('access_token');
//     if (!token) return next('/login');
//     next();
//   } catch (error) {
//     console.error("Error in route guard:", error);
//     next(false);
//   }
// });

export default router;
