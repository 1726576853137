<template>
  <div class="content">
    <van-nav-bar
      title="个人中心"
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
    ></van-nav-bar>
    <div class="count">
      <van-row class="header">
        <van-col span="6">
          <img style="border-radius: 50%;"  :src="userInfo.avatar" alt="" />
        </van-col>
        <van-col v-if="userInfo != null" span="10" class="user-name">{{
          userInfo.nickname
        }}</van-col>
        <van-col span="8">
          <van-button icon="setting-o" round type="info" @click="toInfo()"
            >编辑资料</van-button
          >
        </van-col>
      </van-row>
    </div>
    <div class="main">
      <van-row class="form">
        <van-col class="title" span="24"> 个人信息 </van-col>
        <van-col class="part" span="24">
          <van-row>
            <van-col span="14">
              <img src="../../assets/images/user.png" alt="" />
              <span>账号</span>
            </van-col>
            <van-col span="10" class="value">{{ userInfo.username }}</van-col>
          </van-row>
        </van-col>
        <!-- 密码 临时隐藏 -->
        <!-- <van-col class="part" span="24">
          <van-row>
            <van-col span="14">
              <img src="../../assets/images/pass.png" alt="" />
              <span>密码</span>
            </van-col>
            <van-col span="10" class="value"> {{userInfo.password}} </van-col>
          </van-row>
        </van-col> -->
        <van-col class="part" span="24">
          <van-row>
            <van-col span="14">
              <img src="../../assets/images/phone.png" alt="" />
              <span>联系电话</span>
            </van-col>
            <van-col span="10" class="value"> {{ userInfo.phone }} </van-col>
          </van-row>
        </van-col>
        <van-col class="part" span="24">
          <van-row>
            <van-col span="14">
              <img src="../../assets/images/mail.png" alt="" />
              <span>联系邮箱</span>
            </van-col>
            <van-col span="10" class="value"> {{ userInfo.email }} </van-col>
          </van-row>
        </van-col>
        <van-col span="24" class="btns">
          <van-row>
            <van-col span="24">
              <van-button round type="info" @click="toExit()"
                >退出账号</van-button
              >
            </van-col>
            <van-col span="24">
              <van-button round type="default" @click="toEdit()"
                >修改密码</van-button
              >
            </van-col>
          </van-row>
        </van-col>
      </van-row>
    </div>
  </div>
</template>
    
<script>
import { removeToken } from "@/utils/token-util";
import { Dialog } from "vant";
import { getUserInfo } from "@/api/Home";
export default {
  data() {
    return {
      userInfo: null,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    toEdit() {
      this.$router.push({ path: "/editpass" });
    },
    toInfo() {
      this.$router.push({ path: "/editinfo" });
    },
    toExit() {
      Dialog.confirm({
        // title: '',
        message: "是否要退出账号",
      })
        .then(() => {
          this.$router.push({ path: "/login" });
          removeToken();
        })
        .catch(() => {
          // on cancel
        });
    },
    // 获取用户信息
    async getInfo() {
      try {
        const res = await getUserInfo();
        console.log("homeinfo", res);
        this.userInfo = res.data;
      } catch (error) {
        // console.error('获取数据失败:', error);
      }
    },
  },
  created() {
    this.getInfo();
  },
  watch: {
    $route: function () {
      location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  height: 100%;

  :deep .van-nav-bar {
    background: none !important;
    border: none;

    .van-nav-bar__title {
      color: #fff;
    }

    .van-icon {
      color: #fff;
    }
  }

  .van-hairline--bottom::after {
    border: none;
  }
}

.count {
  width: 100%;
  height: 150px;
  background: url("../../assets/images/home-bg.png") no-repeat;
  background-size: cover;
  overflow: hidden;

  .header {
    margin-top: 50px;
    padding-left: 30px;
    position: relative;
    height: 66px;

    .van-col {
      display: flex;
      height: 100%;
      align-content: center;
      flex-wrap: wrap;
    }

    img {
      width: 66px;
      height: 66px;
    }

    .user-name {
      font-size: 20px;
      color: #fff;
      text-align: left;
      padding-left: 10px;
    }

    .van-button {
      width: 86px;
      height: 27px;
      font-size: 12px;
      padding: 0;
    }
  }
}

.main {
  background: #f8f8f8;
  padding: 12px 10px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  text-align: left;
  height: calc(100% - 140px);
  position: relative;

  .form {
    > .van-col {
      background: #fff;
      padding: 18px;
      margin-top: 10px;
    }

    .title {
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      padding: 22px 16px;
      color: #0a3cca;
      font-size: 16px;
      font-weight: bold;
      margin: 0;
    }

    .part {
      .van-col {
        display: flex;
        align-items: center;

        img {
          width: 18px;
          height: 20px;
          margin-right: 8px;
        }

        span {
          font-size: 12px;
        }
      }

      .value {
        font-size: 14px;
        padding-right: 15px;
        justify-content: end;
      }
    }

    .btns {
      background: none;

      .van-row {
        width: 100%;
      }

      .van-button {
        width: 100%;
      }
    }
  }
}
</style>