import service from "@/router/request";

// 获取评审列表数据
export const pageGroupDrawings = (data) => {
    return service({ url: `/business/groupDrawing/page`, method: `get`, params: data })
}

// 获取用户信息
export const getUserInfo = (data) => {
    return service({ url: `/auth/user`, method: `get`, params: data })
}

// // 获取用户信息
// export const getStatus = (data) => {
//     return service({ url: `/business/groupDrawing/getJuryProgressStatistics`, method: `get`, params: data })
// }

// 修改密码
export const EditPass = (data) => {
    return service({ url: `/auth/password`, method: `put`, data: data })
}